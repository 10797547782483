import { IState } from './state';
import {
  IAddress,
  IAdminPrograms,
  IAllEventsInfo,
  IAvailableField,
  IChangeEvent,
  IChangeProgram,
  IChangeProgramsTracksOrAddress,
  IEditBlocksEvent,
  IGetAllEventsInfoResponseData,
  IInfoBlockSlide,
  IProgramByAddress,
  IProgramByTrack,
  ITrack,
} from '@/services/AdminService/AdminServiceTypes';
import { IDocument, IEvent, ISpeakerCard } from '@/services/EventService/EventServiceTypes';

export const types = {
  SET_EVENT_CHANGES: 'SET_EVENT_CHANGES',
  SET_CHANGED_EVENT_DOCUMENTS: 'SET_CHANGED_EVENT_DOCUMENTS',
  SET_UPLOAD_FILE: 'SET_UPLOAD_FILE',
  SET_UPLOAD_HEADER_IMAGE_EVENT: 'SET_UPLOAD_HEADER_IMAGE_EVENT',
  SET_EVENT_HEADER_IMAGE: 'SET_EVENT_HEADER_IMAGE',
  SET_UPLOAD_IMAGE_EVENT: 'SET_UPLOAD_IMAGE_EVENT',
  SET_UPLOAD_SLIDE_IMAGE_EVENT: 'SET_UPLOAD_SLIDE_IMAGE_EVENT',
  FAILED_UPLOAD_IMAGE: 'FAILED_UPLOAD_IMAGE',
  CLEAR_FAILED_UPLOAD_IMAGE: 'CLEAR_FAILED_UPLOAD_IMAGE',
  CLEAR_EVENT_INFO_BLOCK_IMAGE: 'CLEAR_EVENT_INFO_BLOCK_IMAGE',
  CLEAR_FETCHING_INFO_BLOCK: 'CLEAR_FETCHING_INFO_BLOCK',
  CLEAR_IMAGE_EVENT: 'CLEAR_FIRST_IMAGE_EVENT',
  SET_FETCHING_UPLOAD_FILE: 'SET_FETCHING_UPLOAD_FILE',
  SET_FETCHING_UPLOAD_EVENT_IMAGE: 'SET_FETCHING_UPLOAD_EVENT_IMAGE',
  SET_FETCHING_UPLOAD_EVENT_IMAGE_SLIDE: 'SET_FETCHING_UPLOAD_EVENT_IMAGE_SLIDE',
  SET_CHANGED_EVENT_IMAGES_LIST: 'SET_CHANGED_EVENT_IMAGES_LIST',
  SET_CHANGED_EVENT_VIDEO: 'SET_CHANGED_EVENT_VIDEO',
  SET_CHANGED_INFO_BLOCK_DESCRIPTION: 'SET_CHANGED_INFO_BLOCK_DESCRIPTION',
  SET_CHANGED_INFO_BLOCK_SLIDER: 'SET_CHANGED_INFO_BLOCK_SLIDER',
  CLEAR_CHANGED_INFO_BLOCK_IMAGE: 'CLEAR_CHANGED_INFO_BLOCK_IMAGE',
  CLEAR_REDACTOR_EVENT_CHANGES: 'CLEAR_REDACTOR_EVENT_CHANGES',
  SET_FETCHING_IMAGE_HEADER_EVENT: 'SET_FETCHING_IMAGE_HEADER_EVENT',
  SET_EVENTS_LIST: 'SET_EVENTS_LIST',
  SET_EVENTS_LIST_FETCHING_MORE: 'SET_EVENTS_LIST_FETCHING_MORE',
  ADD_EVENTS_LIST: 'ADD_EVENTS_LIST',
  SET_REDACTOR_EVENT_BLOCKS: 'SET_REDACTOR_EVENT_BLOCKS',
  CLEAR_HEADER_IMAGE_EVENT: 'CLEAR_HEADER_IMAGE_EVENT',
  SET_ADMIN_PROGRAMS: 'SET_ADMIN_PROGRAMS',
  SET_ADMIN_DELETED_PROGRAMS: 'SET_ADMIN_DELETED_PROGRAMS',
  SET_PROGRAM_DOCUMENTS: 'SET_PROGRAM_DOCUMENTS',
  CLEAR_PROGRAM_DOCUMENTS: 'CLEAR_PROGRAM_DOCUMENTS',
  SET_SPEAKER_PRESENTATIONS: 'SET_SPEAKER_PRESENTATIONS',
  CLEAR_SPEAKER_PRESENTATIONS: 'CLEAR_SPEAKER_PRESENTATIONS',
  SET_PROGRAM_SPEAKER_PRESENTATIONS: 'SET_PROGRAM_SPEAKER_PRESENTATIONS',
  SET_UPLOAD_IMAGE_CREATE_PROGRAM: 'SET_UPLOAD_IMAGE_CREATE_PROGRAM',
  SET_FETCHING_UPLOAD_IMAGE_CREATE_PROGRAM: 'SET_FETCHING_UPLOAD_IMAGE_CREATE_PROGRAM',
  SET_FETCHING_UPLOAD_AVATAR_IMAGE: 'SET_FETCHING_UPLOAD_AVATAR_IMAGE',
  CLEAR_CREATE_PROGRAM_HEADER_IMAGE: 'CLEAR_CREATE_PROGRAM_HEADER_IMAGE',
  CREATE_PROGRAM: 'CREATE_PROGRAM',
  SET_ALL_ADDRESS: 'SET_ALL_ADDRESS',
  SET_ALL_TRACKS: 'SET_ALL_TRACKS',
  SET_SEARCH_SPEAKERS_LIST: 'SET_SEARCH_SPEAKERS_LIST',
  CLEAR_SPEAKER_SEARCH: 'CLEAR_SPEAKER_SEARCH',
  SET_SELECTED_TRACK: 'SET_SELECTED_TRACK',
  SET_SELECTED_ADDRESS: 'SET_SELECTED_ADDRESS',
  CLEAR_SELECTED_TRACK: 'CLEAR_SELECTED_TRACK',
  CLEAR_SELECTED_ADDRESS: 'CLEAR_SELECTED_ADDRESS',
  SET_ALL_PROGRAMS_BY_TRACK: 'SET_ALL_PROGRAMS_BY_TRACK',
  SET_ALL_PROGRAMS_BY_ADDRESS: 'SET_ALL_PROGRAMS_BY_ADDRESS',
  SET_PROGRAM_REDACTOR: 'SET_PROGRAM_REDACTOR',
  CHANGE_TRACK_OR_ADDRESS_PROGRAMS: 'CHANGE_TRACK_OR_ADDRESS_PROGRAMS',
  DELETE_TRACK: 'DELETE_TRACK',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
  SET_IF_FETCH_SEARCH_SPEAKERS: 'SET_IF_FETCH_SEARCH_SPEAKERS',
  SET_CREATING_OR_UPDATING_SPEAKER: 'SET_CREATING_OR_UPDATING_SPEAKER',
  SET_SELECTED_PROGRAM: 'SET_SELECTED_PROGRAM',
  CLEAR_SELECTED_PROGRAM: 'CLEAR_SELECTED_PROGRAM',
  SET_UPDATE_PROGRAM: 'SET_UPDATE_PROGRAM',
  SET_AVAILABLE_FIELDS: 'SET_AVAILABLE_FIELDS',
  SET_SELECTED_FIELDS: 'SET_SELECTED_FIELDS',
  DELETE_PROGRAM: 'DELETE_PROGRAM',
  RECOVER_PROGRAM: 'RECOVER_PROGRAM',
  SET_FETCHING_CREATE_EVENT: 'SET_FETCHING_CREATE_EVENT',
  CHECK_IF_EVENT_EXIST: 'CHECK_IF_EVENT_EXIST',
};

export default {
  [types.SET_FETCHING_CREATE_EVENT](state: IState, isFetching: boolean) {
    state.isFetchingCreateEvent = isFetching;
  },
  [types.SET_EVENT_CHANGES](state: IState, body: IChangeEvent) {
    state.redactorEventInfo = { ...state.redactorEventInfo, ...body };
  },
  [types.SET_CHANGED_EVENT_DOCUMENTS](state: IState, changeDocuments: Array<IDocument>) {
    state.changedEventDocuments = changeDocuments;
  },
  [types.SET_UPLOAD_HEADER_IMAGE_EVENT](state: IState, url: string) {
    state.imageRedactorEventHeader = url;
    // state.eventHeroImage = url;
  },
  [types.SET_FETCHING_IMAGE_HEADER_EVENT](state: IState, isFetching: boolean) {
    state.isFetchingEventHeroImage = isFetching;
  },
  [types.SET_EVENT_HEADER_IMAGE](state: IState, url: string) {
    state.eventHeroImage = url;
  },
  [types.SET_UPLOAD_SLIDE_IMAGE_EVENT](state: IState, url: string) {
    state.infoBlockSliderImage = url;
  },
  [types.FAILED_UPLOAD_IMAGE](state: IState, errorCode: number) {
    state.failedUploadImageErrorCode = errorCode;
  },
  [types.CLEAR_FAILED_UPLOAD_IMAGE](state: IState) {
    state.failedUploadImageErrorCode = 0;
  },
  [types.CLEAR_EVENT_INFO_BLOCK_IMAGE](state: IState, removeIndex: number) {
    state.changedEventImages = state.changedEventImages.filter(
      (item, index) => index !== removeIndex
    );
  },
  [types.CLEAR_CHANGED_INFO_BLOCK_IMAGE](state: IState, removeId: number) {
    state.changedInfoBlockSlider.forEach((item) => {
      if (item.id === removeId) {
        return (item.image = '');
      }
    });
  },
  [types.SET_FETCHING_UPLOAD_EVENT_IMAGE_SLIDE](state: IState, isFetching: boolean) {
    state.isFetchingInfoBlockImageUpload = isFetching;
  },
  [types.SET_UPLOAD_IMAGE_EVENT](state: IState, url: string) {
    state.infoBlockImage = url;
  },
  [types.SET_FETCHING_UPLOAD_EVENT_IMAGE](state: IState, isFetching: boolean) {
    state.isFetchingUploadInfoBlockImage = isFetching;
  },

  [types.SET_CHANGED_EVENT_IMAGES_LIST](state: IState, changedEventImagesList: Array<string>) {
    state.changedEventImages = changedEventImagesList;
  },
  [types.SET_CHANGED_EVENT_VIDEO](state: IState, changedEventVideo: string) {
    state.changedEventVideo = changedEventVideo;
  },
  [types.SET_CHANGED_INFO_BLOCK_DESCRIPTION](state: IState, changedInfoBlockDescription: string) {
    state.changedInfoBlockDescription = changedInfoBlockDescription;
  },
  [types.SET_CHANGED_INFO_BLOCK_SLIDER](state: IState, listOfInfo: Array<IInfoBlockSlide>) {
    state.changedInfoBlockSlider = listOfInfo;
  },
  changeBlockSlider(state: IState, list: Array<IInfoBlockSlide>) {
    state.changedBlockSlider = list;
  },
  changeBlockVideo(state: IState, url: string) {
    state.changedBlockVideo = url;
  },
  changeBlockVideoWithText(state: IState, { url, text }: { url: string; text: string }) {
    state.changedBlockVideoWithTextUrl = url;
    state.changedBlockVideoWithTextText = text;
  },
  changeBlockGallery(state: IState, urls: string[]) {
    state.changedBlockGalleryImages = urls;
  },
  changeBlockGalleryWithText(state: IState, { urls, text }: { urls: string[]; text: string }) {
    state.changedBlockGalleryWithTextImages = urls;
    state.changedBlockGalleryWithTextText = text;
  },
  [types.CLEAR_REDACTOR_EVENT_CHANGES](state: IState) {
    state.changedEventDocuments = [];
    state.changedEventImages = [];
    state.changedInfoBlockSlider = [];
    state.changedEventVideo = '';
    state.changedInfoBlockDescription = '';
  },

  [types.SET_EVENTS_LIST](state: IState, getAllEventsInfo: IAllEventsInfo) {
    state.allEvents = getAllEventsInfo;
  },
  [types.SET_EVENTS_LIST_FETCHING_MORE](state: IState, isFetching: boolean) {
    state.isFetchingMoreEvent = isFetching;
  },
  [types.ADD_EVENTS_LIST](state: IState, responseGetAllEventsInfo: IGetAllEventsInfoResponseData) {
    state.allEvents?.events?.push(...(responseGetAllEventsInfo?.events ?? []));
    state.allEvents.currentPage = responseGetAllEventsInfo.currentPage;
    state.allEvents.totalPages = responseGetAllEventsInfo.totalPages;
  },
  [types.SET_UPLOAD_FILE](state: IState, url: string) {
    state.fileUpload = url;
  },
  [types.SET_FETCHING_UPLOAD_FILE](state: IState, isFetching: boolean) {
    state.isFetchingUploadFile = isFetching;
  },
  [types.SET_REDACTOR_EVENT_BLOCKS](state: IState, redactorBlock: IEditBlocksEvent) {
    state.redactorEventBlock = redactorBlock;
  },
  [types.CLEAR_HEADER_IMAGE_EVENT](state: IState) {
    state.imageRedactorEventHeader = '';
  },
  [types.SET_ADMIN_PROGRAMS](
    state: IState,
    adminProgramsInfo: {
      adminPrograms: Array<IAdminPrograms>;
      eventStartDate: string;
      eventEndDate: string;
    }
  ) {
    state.adminPrograms = adminProgramsInfo.adminPrograms;
    state.eventStartDate = adminProgramsInfo.eventStartDate;
    state.eventEndDate = adminProgramsInfo.eventEndDate;
  },
  [types.SET_ADMIN_DELETED_PROGRAMS](state: IState, adminDeletedPrograms: Array<IAdminPrograms>) {
    state.adminDeletedPrograms = adminDeletedPrograms;
  },
  [types.SET_PROGRAM_DOCUMENTS](state: IState, documentList: Array<IDocument>) {
    state.programDocuments = documentList;
  },
  [types.CLEAR_PROGRAM_DOCUMENTS](state: IState) {
    state.programDocuments = [];
  },
  [types.SET_SPEAKER_PRESENTATIONS](state: IState, presentationsList: Array<IDocument>) {
    state.speakerPresentations = presentationsList;
  },
  [types.CLEAR_SPEAKER_PRESENTATIONS](state: IState) {
    state.speakerPresentations = [];
  },
  [types.SET_PROGRAM_SPEAKER_PRESENTATIONS](
    state: IState,
    programSpeakerPresentation: Array<IDocument>
  ) {
    state.programSpeakerPresentation = programSpeakerPresentation;
  },
  [types.SET_UPLOAD_IMAGE_CREATE_PROGRAM](state: IState, url: string) {
    state.imageUploadCreateProgram = url;
  },
  [types.SET_FETCHING_UPLOAD_IMAGE_CREATE_PROGRAM](state: IState, isFetching: boolean) {
    state.isFetchingImageUploadCreateProgram = isFetching;
  },
  [types.SET_FETCHING_UPLOAD_AVATAR_IMAGE](state: IState, isFetching: boolean) {
    state.isFetchingUploadAvatarImage = isFetching;
  },
  [types.SET_UPLOAD_FILE](state: IState, url: string) {
    state.fileUpload = url;
  },
  [types.SET_FETCHING_UPLOAD_FILE](state: IState, isFetching: boolean) {
    state.isFetchingUploadFile = isFetching;
  },
  [types.CLEAR_CREATE_PROGRAM_HEADER_IMAGE](state: IState) {
    state.imageUploadCreateProgram = '';
  },
  [types.SET_ALL_ADDRESS](state: IState, allAddress: Array<IAddress>) {
    state.allAddress = allAddress;
  },
  [types.SET_ALL_TRACKS](state: IState, allTracks: Array<ITrack>) {
    state.allTracks = allTracks;
  },
  [types.SET_SEARCH_SPEAKERS_LIST](state: IState, searchSpeakers: Array<ISpeakerCard>) {
    state.speakerSearch = searchSpeakers;
  },
  [types.SET_IF_FETCH_SEARCH_SPEAKERS](state: IState, isFetching: boolean) {
    state.isFetchSearchSpeaker = isFetching;
  },
  [types.CLEAR_SPEAKER_SEARCH](state: IState) {
    state.speakerSearch = [];
  },
  [types.SET_CREATING_OR_UPDATING_SPEAKER](state: IState, isFetching: boolean) {
    state.isSpeakerCreatingOrUpdating = isFetching;
  },
  [types.SET_SELECTED_TRACK](state: IState, selectedTrack: ITrack) {
    state.selectedTrackInfo = selectedTrack;
  },
  [types.SET_SELECTED_ADDRESS](state: IState, selectedAddress: IAddress) {
    state.selectedAddressInfo = selectedAddress;
  },
  [types.CLEAR_SELECTED_TRACK](state: IState) {
    state.selectedTrackInfo = {} as ITrack;
  },
  [types.CLEAR_SELECTED_ADDRESS](state: IState) {
    state.selectedAddressInfo = {} as IAddress;
  },
  [types.SET_ALL_PROGRAMS_BY_TRACK](state: IState, programsByTrack: Array<IProgramByTrack>) {
    state.programsByTrack = programsByTrack;
  },
  [types.SET_ALL_PROGRAMS_BY_ADDRESS](state: IState, programsByAddress: Array<IProgramByAddress>) {
    state.programsByAddress = programsByAddress;
  },
  [types.SET_PROGRAM_REDACTOR](state: IState, body: IChangeProgram) {
    state.redactorProgram = { ...state.redactorProgram, ...body };
  },
  [types.CHANGE_TRACK_OR_ADDRESS_PROGRAMS](state: IState, body: IChangeProgramsTracksOrAddress) {
    state.redactorProgramsTracksOrAddress = { ...state.redactorProgramsTracksOrAddress, ...body };
  },
  [types.DELETE_TRACK](state: IState, track: ITrack) {
    state.allTracks = state.allTracks.filter((item) => item.uuid !== track.uuid);
  },
  [types.DELETE_ADDRESS](state: IState, address: IAddress) {
    state.allAddress = state.allAddress.filter((item) => item.uuid !== address.uuid);
  },
  [types.SET_SELECTED_PROGRAM](state: IState, uuid: string) {
    state.selectedProgramId = uuid;
  },
  [types.CLEAR_SELECTED_PROGRAM](state: IState) {
    state.selectedProgramId = '';
  },
  [types.SET_UPDATE_PROGRAM](state: IState, updateProgram: IChangeProgram) {
    state.updateProgram = { ...state.updateProgram, ...updateProgram };
  },
  [types.SET_AVAILABLE_FIELDS](state: IState, availableFields: Array<IAvailableField>) {
    state.availableFields = availableFields;
  },
  [types.SET_SELECTED_FIELDS](state: IState, selectedFields: Array<string>) {
    state.selectedFields = selectedFields;
  },
  [types.DELETE_PROGRAM](state: IState, programUUID: string) {
    state.adminPrograms = state.adminPrograms.filter((item) => item.uuid !== programUUID);
  },
  [types.RECOVER_PROGRAM](state: IState, body: IAdminPrograms) {
    state.adminPrograms?.push(body);
  },
  [types.CHECK_IF_EVENT_EXIST](state: IState, hasSlug: boolean) {
    state.hasSlug = hasSlug;
  },
  setActiveDateIndex(state: IState, activeDateIndex: number) {
    state.activeDateIndex = activeDateIndex;
  },
  /** После изменения события, мы должны увидеть изменения в списке событий. */
  mutateEventListAfterChangeEvent(state: IState, changedEvent: IEvent): void {
    if (!state.allEvents.events?.length) {
      return;
    }

    const index = state.allEvents.events?.findIndex((item) => item.uuid === changedEvent.uuid);
    if (index !== -1) {
      state.allEvents.events[index] = changedEvent;
    } else {
      // Если добавили новое, показываем его первым в списке
      state.allEvents.events.unshift(changedEvent);
    }
  },
};
