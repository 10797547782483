import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/utils/custom/toast/vue-toastr-2';
import VueCookies from 'vue-cookies-ts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueMask from 'v-mask';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vClickOutside from 'v-click-outside';

import VueSocialSharing from 'vue-social-sharing';
// @ts-ignore
import VCalendar from 'v-calendar';
// @ts-ignore
import ymapPlugin from 'vue-yandex-maps';
import * as Sentry from '@sentry/vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import '@/assets/styles/tailwind.scss';
// FONTS
import '@/assets/fonts/Suisse-Int/style.css';
import { YandexMetrikaUtil } from '@/utils/yandex-metrika.util';
import VueI18n from 'vue-i18n';
import { useLanguageWatcher } from '@/helpers/composables/lang-watcher.composable';
import { cloneDeep } from 'lodash';
import { languages } from '@/i18n';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: cloneDeep(languages),
});
useLanguageWatcher();

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(VueSocialSharing);
Vue.use(VueCookies);
Vue.use(VueMask);
Vue.use(vClickOutside);
Vue.use(ymapPlugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  pinia,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Для теста локально в env.development прописать VUE_APP_SENTRY_DSN
  enabled: Boolean(process.env.VUE_APP_SENTRY_DSN),
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
YandexMetrikaUtil.init();
