<template>
  <AppModal
    v-if="isShowModal"
    ref="leaveModal"
    :is-show-modal="isShowModal"
    :text-head="`${textHead}`"
    @toggleModal="toggleModal"
  >
    <div class="app-modal-confirm-exit">
      <app-text :text="text" />
      <div class="app-modal-confirm-exit__buttons">
        <app-button
          :text="`${$i18n.t('modals.confirmExit.buttons.cancel')}`"
          @click="toggleModal"
        />
        <app-button :text="`${buttonText}`" color-text="main" type="secondary" @click="onExit" />
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppButton, AppText } from '@/ui/components';
import { EX_$Modal } from '@/classes/content';
import { mapActions, mapState } from 'vuex';
import { i18n } from '@/main';

export default Vue.extend({
  components: {
    AppModal,
    AppText,
    AppButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      EX_ModalClass: EX_$Modal,
    };
  },
  methods: {
    ...mapActions('admin', ['AClearRedactorEventChanges']),
    toggleModal() {
      this.$emit('toggleModal');
    },
    onExit() {
      switch (this.$route.fullPath.split('/')[1]) {
        case 'event-redactor':
          if (
            !this.EX_ModalClass.getIsShowModal('appModalEventDocuments') &&
            !this.EX_ModalClass.getIsShowModal('appModalEventSlider')
          ) {
            this.handleRouteForLeave();
            this.AClearRedactorEventChanges();
          }
          break;
        case 'constructor-admin-event':
          this.handleRouteForLeave();
          break;
        case 'create-event':
          this.handleRouteForLeave();
          break;
        // case 'program-control-panel':
        //   if (!this.EX_ModalClass.getIsShowModal('appModalCreateProgram')) {
        //     this.handleRouteForLeave();
        //   }
        //   break;
        case 'profile-page':
          if (
            !this.EX_ModalClass.getIsShowModal('appModalEditDescription') &&
            !this.EX_ModalClass.getIsShowModal('appModalEditCareer') &&
            !this.EX_ModalClass.getIsShowModal('appModalEditEducation') &&
            !this.EX_ModalClass.getIsShowModal('appModalEditUser')
          ) {
            this.handleRouteForLeave();
          }

          break;
      }
      this.EX_ModalClass.closeAllModal();
    },
    handleRouteForLeave() {
      const currentRouteForLeave = localStorage.getItem('currentRouteForLeave');
      if (!currentRouteForLeave) return;

      switch (currentRouteForLeave) {
        case '/admin-events?category=all':
          this.$router.push({
            name: 'AdminEvents',
            query: { category: 'all' },
            params: {
              scrollBehaviour: `event-${this.eventInfo.uuid}`,
            },
          });
          break;
        case '/admin-events?category=publish':
          this.$router.push({
            name: 'AdminEvents',
            query: { category: 'publish' },
            params: {
              scrollBehaviour: `event-${this.eventInfo.uuid}`,
            },
          });
          break;
        case '/admin-events?category=unpublish':
          this.$router.push({
            name: 'AdminEvents',
            query: { category: 'unpublish' },
            params: {
              scrollBehaviour: `event-${this.eventInfo.uuid}`,
            },
          });
          break;
        case '/admin-events?category=deleted':
          this.$router.push({
            name: 'AdminEvents',
            query: { category: 'deleted' },
            params: {
              scrollBehaviour: `event-${this.eventInfo.uuid}`,
            },
          });
          break;
        default:
          this.$router.push(currentRouteForLeave);
          break;
      }
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
    textHead() {
      switch (this.$route.fullPath.split('/')[1]) {
        case 'event':
          return i18n.t('modals.confirmExit.text.completion');
        default:
          return i18n.t('modals.confirmExit.text.saving');
      }
    },
    text() {
      switch (this.$route.fullPath.split('/')[1]) {
        case 'event':
          return i18n.t('modals.confirmExit.text.unregisteredOut');
        default:
          return i18n.t('modals.confirmExit.text.unsaveOut');
      }
    },
    buttonText() {
      switch (this.$route.fullPath.split('/')[1]) {
        case 'event':
          return i18n.t('modals.confirmExit.buttons.unregisteredOut');
        default:
          return i18n.t('modals.confirmExit.buttons.unsaveOut');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'style';
</style>
