<template>
  <div
    v-if="langList?.length > 1"
    class="w-full flex gap-2.5 bg-neutral-100 p-[7px] rounded-md mb-[27px] box-border"
  >
    <button
      v-for="lang in langList"
      :key="lang"
      type="button"
      class="w-full flex items-center justify-center gap-2.5 py-[7px] text-base font-medium cursor-pointer box-border rounded-sm"
      :class="{
        'bg-white text-blue': i18n.locale === lang,
        'bg-neutral-100 text-grey': i18n.locale !== lang,
      }"
      @click="handleSwitchLang(lang)"
    >
      <span class="flex items-center"><FlagIcon :lang="lang" /></span>
      <span class="text-base font-medium">{{ lang.toUpperCase() }}</span>
    </button>
  </div>
</template>

<script lang="ts">
export default { name: 'LangSwitcher' };
</script>

<script setup lang="ts">
import { ref } from 'vue';
import FlagIcon from '@/components/event/flag-icon/FlagIcon.vue';
import store from '@/store';
import { i18n } from '@/main';
import { TLangs } from '@/helpers/types/shared.types';
import { setLocale } from '@/i18n';

const emits = defineEmits();
const props = defineProps();

const langList = ref(store.state.event.eventInfo.pageCustomization?.locales);

const handleSwitchLang = (lang: TLangs) => {
  if (i18n.locale === lang) {
    return;
  }

  setLocale(lang);
  localStorage.setItem('lang', lang);
};
</script>

<style scoped lang="scss"></style>
