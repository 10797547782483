<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :is-hide-icon-close="true"
  >
    <div class="app-modal-registration-success__card" :style="getBackground">
      <div class="app-modal-registration-success__card-shadow">
        <app-text
          class="app-modal-registration-success__event-name"
          type="fourthTitle"
          color="button-title"
          :text="eventInfo.title"
        />
      </div>
    </div>
    <div class="app-modal-registration-success__title">
      <app-text type="title" :text="`${$t('modals.registrationSuccess.text.success')}`" />
    </div>
    <div class="app-modal-registration-success__subtitle">
      <app-text
        type="main-book"
        color="secondary"
        :text="`${$t('modals.registrationSuccess.text.reminder')}`"
      />
    </div>
    <app-button
      :text="`${$t('modals.registrationSuccess.buttons.success')}`"
      :is-disabled="false"
      @click="toggleModal"
    />
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppModal from '@/ui/components/modals/app-modal/AppModal.vue';
import { AppButton, AppText } from '@/ui/components';
import AppIcon from '@/ui/components/icons/app-icon/AppIcon.vue';
import { MWidthWindow } from '@/mixins';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'appModalRegistrationSuccess',
  mixins: [MWidthWindow],
  components: {
    AppModal,
    AppText,
    AppIcon,
    AppButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isPassHidden: true,
    };
  },
  methods: {
    toggleModal() {
      return this.$emit('toggleModal');
    },
  },
  computed: {
    ...mapState('event', ['eventInfo']),
    getBackground() {
      return `background: url(${this.eventInfo.image}) center center/cover no-repeat;`;
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'style';
</style>
