import { render, staticRenderFns } from "./LangSwitcher.vue?vue&type=template&id=65eb1746&scoped=true"
import script from "./LangSwitcher.vue?vue&type=script&setup=true&lang=ts"
export * from "./LangSwitcher.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65eb1746",
  null
  
)

export default component.exports