<template>
  <div class="flex items-center justify-center">
    <svg
      v-if="lang === 'ru'"
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="20"
      viewBox="0 0 31 20"
      fill="none"
    >
      <g clip-path="url(#clip0_572_15143)">
        <g filter="url(#filter0_d_572_15143)">
          <g filter="url(#filter1_d_572_15143)">
            <path
              d="M30.7633 6.66675H0.236816V0.526343C0.236816 0.235685 0.472502 0 0.763159 0H30.237C30.5277 0 30.7633 0.235685 30.7633 0.526343L30.7633 6.66675Z"
              fill="#F5F5F5"
            />
          </g>
          <g filter="url(#filter2_d_572_15143)">
            <path
              d="M30.2369 19.9999H0.763159C0.472502 19.9999 0.236816 19.7643 0.236816 19.4736V13.3333H30.7633V19.4736C30.7633 19.7643 30.5276 19.9999 30.2369 19.9999Z"
              fill="#FF4B55"
            />
          </g>
          <g filter="url(#filter3_d_572_15143)">
            <path d="M30.7633 6.6665H0.236816V13.3328H30.7633V6.6665Z" fill="#41479B" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_572_15143"
          x="-12.4826"
          y="-12.7194"
          width="55.9651"
          height="45.4388"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.35969" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_572_15143" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_572_15143"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_572_15143"
          x="-12.4826"
          y="-12.7194"
          width="55.9651"
          height="32.1055"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.35969" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_572_15143" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_572_15143"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_572_15143"
          x="-12.4826"
          y="0.613866"
          width="55.9651"
          height="32.1055"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.35969" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_572_15143" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_572_15143"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_572_15143"
          x="-12.4826"
          y="-6.05288"
          width="55.9651"
          height="32.105"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.35969" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_572_15143" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_572_15143"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_572_15143">
          <rect x="0.236816" width="30.5265" height="20" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>

    <svg
      v-if="lang === 'en'"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="20"
      viewBox="0 0 32 20"
      fill="none"
    >
      <g clip-path="url(#clip0_572_15151)">
        <g filter="url(#filter0_d_572_15151)">
          <path
            d="M30.7361 19.9999H1.26267C0.972011 19.9999 0.736328 19.7642 0.736328 19.4736V0.526337C0.736328 0.235683 0.972011 0 1.26267 0H30.7362C31.0268 0 31.2625 0.235683 31.2625 0.526337V19.4736C31.2625 19.7642 31.0268 19.9999 30.7361 19.9999Z"
            fill="#41479B"
          />
          <path
            d="M31.2625 0.526337C31.2625 0.235683 31.0268 0 30.7361 0H28.3814L18.6309 6.38825V0H13.3678V6.38825L3.61735 0H1.26267C0.972011 0 0.736328 0.235683 0.736328 0.526337V1.88755L9.10174 7.36837H0.736328V12.6315H9.10174L0.736328 18.1124V19.4736C0.736328 19.7642 0.972011 19.9999 1.26267 19.9999H3.61741L13.3679 13.6117V19.9999H18.631V13.6117L28.3815 19.9999H30.7362C31.0269 19.9999 31.2626 19.7642 31.2626 19.4736V18.1124L22.8972 12.6316H31.2625V7.36843H22.8971L31.2625 1.88755V0.526337Z"
            fill="#F5F5F5"
          />
          <path
            d="M31.2625 8.42104H17.5783V0H14.4204V8.42104H0.736328V11.5789H14.4204V19.9999H17.5783V11.5789H31.2625V8.42104Z"
            fill="#FF4B55"
          />
          <path
            d="M11.4057 12.6316L0.751465 19.5475C0.788728 19.8017 0.998297 20 1.26284 20H1.98777L13.3391 12.6317H11.4057V12.6316Z"
            fill="#FF4B55"
          />
          <path
            d="M21.389 12.6316H19.4556L30.7904 19.9891C31.0545 19.961 31.263 19.7453 31.263 19.4736V19.0409L21.389 12.6316Z"
            fill="#FF4B55"
          />
          <path
            d="M0.736816 1.10728L10.3823 7.36836H12.3157L1.04345 0.0512695C0.863393 0.134918 0.736816 0.314676 0.736816 0.526273V1.10728Z"
            fill="#FF4B55"
          />
          <path
            d="M20.5647 7.36836L31.2445 0.435772C31.2002 0.190013 30.9951 0 30.7365 0H29.9825L18.6313 7.36836H20.5647Z"
            fill="#FF4B55"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_572_15151"
          x="-11.9829"
          y="-12.7193"
          width="55.9654"
          height="45.4385"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.35964" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_572_15151" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_572_15151"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_572_15151">
          <rect x="0.736328" width="30.5269" height="20" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script lang="ts">
export default { name: 'FlagIcon' };
</script>

<script setup lang="ts">
defineProps<{ lang: 'en' | 'ru' }>();
</script>

<style scoped lang="scss"></style>
