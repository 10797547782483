import { watch } from 'vue';
import { setLocale } from '@/i18n';
import { TLangs } from '@/helpers/types/shared.types';
import store from '@/store';

export function useLanguageWatcher() {
  watch(
    () => store.state.event.eventInfo.pageCustomization?.locales,
    (newLangs) => {
      const LANG_RU = 'ru';
      const LANG_EN = 'en';

      const browserLang = navigator.language.toLowerCase().includes(LANG_RU) ? LANG_RU : LANG_EN;
      const localStorageLang = localStorage.getItem('lang');
      const newLocale = newLangs?.length > 1 ? localStorageLang || browserLang : 'ru';

      setLocale(newLocale as TLangs);
    },
    { immediate: true }
  );
}
