import {
  IAddress,
  IChangeEvent,
  IChangeProgram,
  IChangeProgramsTracksOrAddress,
  ICreateEventRequestData,
  ICreateProgramRequestData,
  IEditBlocksEvent,
  IGetAllEventsInfoRequestParams,
  IInfoBlockSlide,
  IProgramByAddress,
  IProgramByTrack,
  ITrack,
} from '@/services/AdminService/AdminServiceTypes';
import { Commit } from 'vuex';
import { types } from '@/store/modules/admin/mutations';
import { AdminService, EventService } from '@/services';
import { EX_$Observer } from '@/classes/base';
import { IDocument, ISpeakerCard } from '@/services/EventService/EventServiceTypes';
import { AxiosError } from 'axios';
import EX_Toaster from '@/classes/content/toast';

export default {
  async APatchEventChange({ commit }: { commit: Commit }, body: IChangeEvent): Promise<void> {
    try {
      await AdminService.patchEventChange(body);
      commit(types.SET_EVENT_CHANGES, body);
    } catch (e) {
      console.log(e);
    }
  },
  // Изменяет ссылку на изображение напрямую, внутри модалки изменения фото
  AChangeEventHeaderImage({ commit }: { commit: Commit }, changeImage: string): void {
    commit(types.SET_UPLOAD_HEADER_IMAGE_EVENT, changeImage);
  },
  // Очищает ссылку внутри модалки изменения фото, используется при открытии/закрытии модалки
  AClearEventHeaderImage({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_HEADER_IMAGE_EVENT);
  },
  AChangeEventDocuments({ commit }: { commit: Commit }, changeDocuments: Array<IDocument>): void {
    commit(types.SET_CHANGED_EVENT_DOCUMENTS, changeDocuments);
  },
  AChangeEventImages({ commit }: { commit: Commit }, changeEventImages: Array<string>): void {
    commit(types.SET_CHANGED_EVENT_IMAGES_LIST, changeEventImages);
  },
  AChangeEventVideo({ commit }: { commit: Commit }, changeEventVideo: string): void {
    commit(types.SET_CHANGED_EVENT_VIDEO, changeEventVideo);
  },
  AChangeInfoBlockDescription({ commit }: { commit: Commit }, description: string): void {
    commit(types.SET_CHANGED_INFO_BLOCK_DESCRIPTION, description);
  },
  AChangeInfoBlockSlider({ commit }: { commit: Commit }, listOfInfo: Array<IInfoBlockSlide>): void {
    commit(types.SET_CHANGED_INFO_BLOCK_SLIDER, listOfInfo);
  },
  changeBlockSlider({ commit }: { commit: Commit }, list: Array<IInfoBlockSlide>): void {
    commit('changeBlockSlider', list);
  },
  changeBlockVideo({ commit }: { commit: Commit }, url: string): void {
    commit('changeBlockVideo', url);
  },
  changeBlockVideoWithText(
    { commit }: { commit: Commit },
    { url, text }: { url: string; text: string }
  ): void {
    commit('changeBlockVideoWithText', { url, text });
  },
  changeBlockGallery({ commit }: { commit: Commit }, urls: string[]): void {
    commit('changeBlockGallery', urls);
  },
  changeBlockGalleryWithText(
    { commit }: { commit: Commit },
    { urls, text }: { urls: string[]; text: string }
  ): void {
    commit('changeBlockGalleryWithText', { urls, text });
  },
  AClearRedactorEventChanges({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_REDACTOR_EVENT_CHANGES);
  },
  async AUploadEventInfoBlockSliderImage(
    { commit }: { commit: Commit },
    body: FormData
  ): Promise<void> {
    commit(types.SET_FETCHING_UPLOAD_EVENT_IMAGE_SLIDE, true);
    try {
      const res = await EventService.uploadImageEvent(body);
      commit(types.SET_UPLOAD_SLIDE_IMAGE_EVENT, res.url);
      commit(types.FAILED_UPLOAD_IMAGE, 0);
    } catch (error) {
      const errorCode = error as AxiosError;
      commit(types.SET_FETCHING_UPLOAD_EVENT_IMAGE_SLIDE, false);
      commit(types.FAILED_UPLOAD_IMAGE, errorCode.response?.status);
      console.log(error);
    } finally {
      commit(types.SET_FETCHING_UPLOAD_EVENT_IMAGE_SLIDE, false);
    }
  },
  ClearEventInfoBlockImageById({ commit }: { commit: Commit }, removeId: number) {
    commit(types.CLEAR_CHANGED_INFO_BLOCK_IMAGE, removeId);
  },
  ClearFailedUploadImageErrorCode({ commit }: { commit: Commit }) {
    commit(types.CLEAR_FAILED_UPLOAD_IMAGE);
  },
  async AUploadImageEvent({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_UPLOAD_EVENT_IMAGE, true);
    try {
      const res = await EventService.uploadImageEvent(body);
      commit(types.FAILED_UPLOAD_IMAGE, 0);
      commit(types.SET_UPLOAD_IMAGE_EVENT, res.url);
      commit(types.SET_FETCHING_UPLOAD_EVENT_IMAGE, false);
    } catch (error) {
      const errorCode = error as AxiosError;
      commit(types.FAILED_UPLOAD_IMAGE, errorCode.response?.status);
      commit(types.SET_FETCHING_UPLOAD_EVENT_IMAGE, false);
      console.log(error);
    }
  },

  // Загружает изображение в сторадж внутри модалки изменения фото
  async AUploadHeaderImage({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_IMAGE_HEADER_EVENT, true);
    try {
      const res = await EventService.uploadImageEvent(body);
      commit(types.SET_UPLOAD_HEADER_IMAGE_EVENT, res.url);
      commit(types.SET_FETCHING_IMAGE_HEADER_EVENT, false);
    } catch (e) {
      commit(types.SET_FETCHING_IMAGE_HEADER_EVENT, false);
      console.log(e);
    }
  },
  // Устанавливает ссылку из модалки изменения фото в данные на странице редактирования события
  setEventHeaderImage({ commit }: { commit: Commit }, url: string): void {
    commit(types.SET_EVENT_HEADER_IMAGE, url);
  },
  async AGetAllEvents(
    { commit }: { commit: Commit },
    body: IGetAllEventsInfoRequestParams
  ): Promise<void> {
    try {
      const res = await AdminService.GetAllEvents(body);
      commit(types.SET_EVENTS_LIST, res);
    } catch (e) {
      console.log(e);
    }
  },
  async AGetEventListLoadMore(
    { commit }: { commit: Commit },
    params: IGetAllEventsInfoRequestParams
  ): Promise<void> {
    commit(types.SET_EVENTS_LIST_FETCHING_MORE, true);
    try {
      const res = await AdminService.GetAllEvents(params);
      commit(types.ADD_EVENTS_LIST, res);
    } catch (e) {
      console.log(e);
    }
    commit(types.SET_EVENTS_LIST_FETCHING_MORE, false);
  },
  async AEditBlocksEvent({ commit }: { commit: Commit }, body: IEditBlocksEvent): Promise<void> {
    try {
      const res = await AdminService.editBlocksEvent(body);
      commit('mutateEventListAfterChangeEvent', res);
    } catch (e) {
      console.log(e);
    }
  },
  async ACreateEvent({ commit }: { commit: Commit }, body: ICreateEventRequestData): Promise<void> {
    try {
      commit(types.SET_FETCHING_CREATE_EVENT, true);
      const res = await AdminService.createEvent(body);
      commit('mutateEventListAfterChangeEvent', res);
      commit(types.SET_SELECTED_FIELDS, []);
      await EX_$Observer.context.$router.push({
        name: 'AdminEvents',
        query: { category: 'all' },
        params: { scrollBehaviour: `event-${res.uuid}` },
      });
    } catch (e) {
      EX_Toaster.error('Не удалось создать событие');
    } finally {
      commit(types.SET_FETCHING_CREATE_EVENT, false);
    }
  },

  async AGetAdminPrograms(
    { commit }: { commit: Commit },
    params: { eventUUID: string; search: string }
  ): Promise<void> {
    try {
      const res = await AdminService.getAdminPrograms(params);
      commit(types.SET_ADMIN_PROGRAMS, res);
    } catch (e) {
      console.log(e);
    }
  },
  async AGetAdminDeletedPrograms(
    { commit }: { commit: Commit },
    params: { eventUUID: string; search: string }
  ): Promise<void> {
    try {
      const res = await AdminService.getAdminDeletedPrograms(params);
      commit(types.SET_ADMIN_DELETED_PROGRAMS, res);
    } catch (e) {
      console.log(e);
    }
  },

  ASetProgramDocuments({ commit }: { commit: Commit }, programDocuments: Array<IDocument>): void {
    commit(types.SET_PROGRAM_DOCUMENTS, programDocuments);
  },
  AClearProgramDocuments({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_PROGRAM_DOCUMENTS);
  },

  ASetSpeakerPresentations(
    { commit }: { commit: Commit },
    speakerPresentations: Array<IDocument>
  ): void {
    commit(types.SET_SPEAKER_PRESENTATIONS, speakerPresentations);
  },

  AClearSpeakerPresentations({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_SPEAKER_PRESENTATIONS);
  },

  ASetProgramPresentations(
    { commit }: { commit: Commit },
    programSpeakerPresentations: Array<IDocument>
  ): void {
    commit(types.SET_PROGRAM_SPEAKER_PRESENTATIONS, programSpeakerPresentations);
  },

  async AUploadImageCreateProgram({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_UPLOAD_IMAGE_CREATE_PROGRAM, true);
    try {
      const res = await EventService.uploadImageEvent(body);
      commit(types.SET_UPLOAD_IMAGE_CREATE_PROGRAM, res.url);
    } catch (e) {
      console.log(e);
    } finally {
      commit(types.SET_FETCHING_UPLOAD_IMAGE_CREATE_PROGRAM, false);
    }
  },
  async AUploadAvatarImage({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_UPLOAD_AVATAR_IMAGE, true);
    try {
      const res = await EventService.uploadImageEvent(body);
      return res.url;
    } catch (e) {
      console.log(e);
    } finally {
      commit(types.SET_FETCHING_UPLOAD_AVATAR_IMAGE, false);
    }
  },
  async ACreateSpeakerClient(
    { commit }: { commit: Commit },
    body: Pick<ISpeakerCard, 'info'>
  ): Promise<void> {
    commit(types.SET_CREATING_OR_UPDATING_SPEAKER, true);
    try {
      return await EventService.saveSpeaker(body);
    } catch (e) {
      console.log(e);
    } finally {
      commit(types.SET_CREATING_OR_UPDATING_SPEAKER, false);
    }
  },
  async AEditSpeakerClient(
    { commit }: { commit: Commit },
    body: { info: Pick<ISpeakerCard, 'info'> } & { uuid: string }
  ): Promise<void> {
    commit(types.SET_CREATING_OR_UPDATING_SPEAKER, true);
    try {
      return await EventService.editSpeaker(body.info, body.uuid);
    } catch (e) {
      console.log(e);
    } finally {
      commit(types.SET_CREATING_OR_UPDATING_SPEAKER, false);
    }
  },
  async AUploadFileCreateProgram({ commit }: { commit: Commit }, body: FormData): Promise<void> {
    commit(types.SET_FETCHING_UPLOAD_FILE, true);
    try {
      const res = await AdminService.uploadAll(body);
      commit(types.SET_UPLOAD_FILE, res.url);
    } catch (e) {
      console.log(e);
    } finally {
      commit(types.SET_FETCHING_UPLOAD_FILE, false);
    }
  },
  AClearCreateProgramHeaderImage({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_CREATE_PROGRAM_HEADER_IMAGE);
  },
  async ACreateProgram(
    { commit }: { commit: Commit },
    body: ICreateProgramRequestData
  ): Promise<any> {
    try {
      return AdminService.createProgram(body);
    } catch (e) {
      console.log(e);
    }
  },
  async AGetAllAddress({ commit }: { commit: Commit }, eventUUID: string): Promise<void> {
    try {
      const res = await AdminService.getAllAddress(eventUUID);
      commit(types.SET_ALL_ADDRESS, res);
    } catch (e) {
      console.log(e);
    }
  },
  async AGetAllTracks({ commit }: { commit: Commit }, eventUUID: string): Promise<void> {
    try {
      const res = await AdminService.getAllTracks(eventUUID);
      commit(types.SET_ALL_TRACKS, res);
    } catch (e) {
      console.log(e);
    }
  },
  async ASearchSpeakers({ commit }: { commit: Commit }, search: string): Promise<void> {
    try {
      commit(types.SET_IF_FETCH_SEARCH_SPEAKERS, true);
      const res = await AdminService.searchSpeakers(search);
      commit(types.SET_SEARCH_SPEAKERS_LIST, res);
    } catch (e) {
      console.log(e);
    } finally {
      commit(types.SET_IF_FETCH_SEARCH_SPEAKERS, false);
    }
  },
  AClearSpeakerSearch({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_SPEAKER_SEARCH);
  },
  async ACreateTrack({ commit }: { commit: Commit }, body: ITrack): Promise<void> {
    try {
      await AdminService.createTrack(body);
    } catch (e) {
      console.log(e);
    }
  },
  async ACreateAddress({ commit }: { commit: Commit }, body: IAddress): Promise<void> {
    try {
      await AdminService.createAddress(body as any);
    } catch (e) {
      console.log(e);
    }
  },
  ASetSelectedTrack({ commit }: { commit: Commit }, selectedTrack: ITrack): void {
    commit(types.SET_SELECTED_TRACK, selectedTrack);
  },
  ASetSelectedAddress({ commit }: { commit: Commit }, selectedAddress: IAddress): void {
    commit(types.SET_SELECTED_ADDRESS, selectedAddress);
  },
  AClearSelectedTrack({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_SELECTED_TRACK);
  },
  AClearSelectedAddress({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_SELECTED_ADDRESS);
  },
  async AGetProgramsByTrack({ commit }: { commit: Commit }, body: IProgramByTrack): Promise<void> {
    try {
      const res = await AdminService.getProgramsByTrack(body);
      commit(types.SET_ALL_PROGRAMS_BY_TRACK, res);
    } catch (e) {
      console.log(e);
    }
  },
  async AGetProgramsByAddress(
    { commit }: { commit: Commit },
    body: IProgramByAddress
  ): Promise<void> {
    try {
      const res = await AdminService.getProgramsByAddress(body as any);
      commit(types.SET_ALL_PROGRAMS_BY_ADDRESS, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ARedactorProgram({ commit }: { commit: Commit }, body: IChangeProgram): Promise<void> {
    try {
      await AdminService.redactorProgram(body);
      commit(types.SET_PROGRAM_REDACTOR, body);
    } catch (e) {
      console.log(e);
    }
  },
  async APatchRedactorTracksOrAddressOfPrograms(
    { commit }: { commit: Commit },
    body: IChangeProgramsTracksOrAddress
  ): Promise<void> {
    try {
      await AdminService.redactorTracksOrAddressOfProgram(body);
      commit(types.CHANGE_TRACK_OR_ADDRESS_PROGRAMS, body);
    } catch (e) {
      console.log(e);
    }
  },

  async ARemoveTrack({ commit }: { commit: Commit }, track: ITrack): Promise<void> {
    try {
      const res = await AdminService.deleteTrack(track);
      commit(types.DELETE_TRACK, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ARemoveAddress({ commit }: { commit: Commit }, address: ITrack): Promise<void> {
    try {
      const res = await AdminService.deleteAddress(address as any);
      commit(types.DELETE_ADDRESS, res);
    } catch (e) {
      console.log(e);
    }
  },
  ASetSelectedProgram({ commit }: { commit: Commit }, uuid: string): void {
    commit(types.SET_SELECTED_PROGRAM, uuid);
  },
  AClearSelectedProgram({ commit }: { commit: Commit }): void {
    commit(types.CLEAR_SELECTED_PROGRAM);
  },

  async AUpdateProgram({ commit }: { commit: Commit }, body: IChangeProgram): Promise<void> {
    try {
      await AdminService.AUpdateProgram(body);
      commit(types.SET_UPDATE_PROGRAM, body);
    } catch (e) {
      console.log(e);
    }
  },
  async AGetAvailableFields({ commit }: { commit: Commit }): Promise<void> {
    try {
      const result = await AdminService.AGetAvailableFields();
      commit(types.SET_AVAILABLE_FIELDS, result);
    } catch (e) {
      console.log(e);
    }
  },
  ASetSelectedFields({ commit }: { commit: Commit }, selectedFields: Array<string>): void {
    commit(types.SET_SELECTED_FIELDS, selectedFields);
  },
  async ADeleteProgram(
    { commit }: { commit: Commit },
    params: { programUUID: string; eventUUID: string }
  ): Promise<void> {
    try {
      const res = await AdminService.deleteProgram(params);
      commit(types.DELETE_TRACK, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ARecoverProgram(
    { commit }: { commit: Commit },
    params: { programUUID: string; eventUUID: string }
  ): Promise<void> {
    try {
      const res = await AdminService.recoverProgram(params);
      commit(types.RECOVER_PROGRAM, res);
    } catch (e) {
      console.log(e);
    }
  },

  async ACheckIfEventExist(
    { commit }: { commit: Commit },
    params: { title: string; slug: string }
  ): Promise<void> {
    try {
      const res = await AdminService.checkIfEventExist(params);
      commit(types.CHECK_IF_EVENT_EXIST, res.hasSlug);
    } catch (e) {
      console.log(e);
    }
  },
};
