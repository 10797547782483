import { i18n } from '@/main';
import { TLangs } from '@/helpers/types/shared.types';
import ru from './ru.json';
import en from './en.json';

export const languages = { ru, en };

export function setLocale(newLocale: TLangs) {
  i18n.locale = newLocale;
}
